<template>
  <component
    :is="componentName(element, components)"
    v-for="element in page.elements"
    :key="element.id"
    :element="element"
  />
</template>

<script lang="ts" setup>
  import { defineAsyncComponent } from "vue"
  import { useNotificationStore } from "~/pinia/notifications"

  const notificationStore = useNotificationStore()
  const props = defineProps<{
    page: AlchemyPage
  }>()

  const { componentName } = useAlchemy()

  const components = {
    headline: defineAsyncComponent(
      () => import("~/alchemy/elements/headline.vue"),
    ),
    newsletter_hero: defineAsyncComponent(
      () => import("~/alchemy/elements/newsletter_hero.vue"),
    ),
    newsletter_list: defineAsyncComponent(
      () => import("~/alchemy/elements/newsletter_list.vue"),
    ),
    text_block: defineAsyncComponent(
      () => import("~/alchemy/elements/text_block.vue"),
    ),
  }

  useHead(usePageInfo(props.page))
  onMounted(() => {
    notificationStore.announce(props.page.title)
  })
</script>

<style></style>
